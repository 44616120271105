import {request} from "@/utils/axios";




//获取企业信息
export function queryEnterpriseInfo(){
    return request.post('/portal/service/member/queryEnterpriseInfo.do')    
}
 //获取企业子账户列表
export function querySubUserList(params){
    return request.post('/portal/service/member/queryBusinessSubUserList.do',params)    
}
//创建企业子账户
export function createSubUsers(params){
    return request.post('/portal/service/member/createBusinessSubUsers.do',params)    
}
//重置企业子账户密码
export function resetSubPassword(params){
    return request.post('/portal/service/member/resetBusinessSubPassword.do',params)    
}
//删除子账户
export function deleteSubUsers(params){
    return request.post('/portal/service/member/deleteBusinessSubUsers.do',params)    
}


