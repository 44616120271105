
import store from "@/store";
import domMessage from "@/utils/messageOnce";
import enLocale from '@/assets/lang/en.js'
import zhLocale from '@/assets/lang/zh.js'
function getLangObj(){
  let langObj=null
  if(localStorage.getItem('language')&&localStorage.getItem('language')=='en'){
    langObj=enLocale
  }else{
    langObj=zhLocale
  }
  return langObj
}
const messageOnce = new domMessage();
const menu=[
    {
      path: '/',
      redirect: '/home',
      meta:{
        isIntercept:false,//是否路由拦截到登录
      },
    },
    //todo 产品库
    {
      path: '/engineer',
      name: 'EngineerIndex',
      redirect:'/engineer/clacsuccess',
      meta:{
        isIntercept:true,//是否路由拦截到登录
      },
      component:() => import(/* webpackChunkName: "EngineerIndex" */ '../views/EngineerIndex.vue'),
      children:[
        {
          path: '/engineer/clacsuccess',
          name: 'CalcSuccess',
          meta:{
            label: 'Success',
            bindOptions:true,
            isIntercept:true,//是否路由拦截到登录
            },
          component: () => import(/* webpackChunkName: "CalcSuccess" */ '../views/engineer/CalcSuccess.vue')
        },
        {
              path: '/engineer/calcfail',
              name: 'CalcFail',
              meta:{
                  label: 'Fail',
                  isIntercept:true,//是否路由拦截到登录
              },
              component: () => import(/* webpackChunkName: "CalcFail" */ '../views/engineer/CalcFail.vue'),
        },
        {
            path: '/engineer/calcunderway',
            name: 'CalcUnderway',
            meta:{
                label: 'Computing',
                isIntercept:true,//是否路由拦截到登录
            },
            component: () => import(/* webpackChunkName: "CalcUnderway" */ '../views/engineer/CalcUnderway.vue'),
        },
        {
          path: '/engineer/calcdraft',
          name: 'CalcDraft',
          meta:{
              label: 'Draft',
              isIntercept:true,//是否路由拦截到登录
          },
          component: () => import(/* webpackChunkName: "CalcDraft" */ '../views/engineer/CalcDraft.vue'),
        },
        {
          path: '/engineer/support',
          name: 'ProductSupport',
          meta:{
              label: 'Support',
              bindOptions:true,
              statusOptions:true,
              isIntercept:true,//是否路由拦截到登录
          },
          component: () => import(/* webpackChunkName: "ProductSupport" */ '../views/engineer/ProductSupport.vue'),
        },
        {
        path: '/engineer/classification/:id',
        name: 'ProductClassification',
        meta:{
          bindOptions:true,
          statusOptions:true,
          label: 'folder',
          isIntercept:true,//是否路由拦截到登录
      },
        component: () => import(/* webpackChunkName: "ProductClassification" */ '../views/engineer/Classification.vue'),
        },
      ]
    },
    //! 新建设计页面
    {
      path: '/createdesign',
      name: 'CreateDesign',
      meta:{
        isIntercept:true,//是否路由拦截到登录
      },
      component:() => import(/* webpackChunkName: "CreateDesign" */ '../components/engineer/CreateDesign.vue'),
    },
    //todo 结果页面
    {
      path: '/result/:productUUID',
      name: 'ResultIndex',
      meta:{
        isIntercept:true,//是否路由拦截到登录
      },
      redirect:'/result/:productUUID/import',
      component:() => import(/* webpackChunkName: "ResultIndex" */ '../views/ResultIndex.vue'),
      //路由守卫
      // beforeEnter:(to,from,next)=>{
      //   if(store.state.productUUID){//判断当前产品id
      //       next()//执行
      //   }else{
      //     messageOnce.error({
      //       message: getLangObj().routeMenu.messageProduct, //报错信息
      //       type: "error",
      //       showClose:true
      //     });
      //     next('/engineer')
      //   }
      // },
      children:[
        {
          path: 'housing',
          name: 'housing',
          meta:{
            label: 'Housing',
            isIntercept:true,//是否路由拦截到登录
          },
          component: () => import(/* webpackChunkName: "ImportHousing" */ '../views/result/importHousing.vue'),
        },
        {
          path: 'importapply',
          name: 'ImportApply',
          meta:{
            label: 'ImportApply',
            isIntercept:true,//是否路由拦截到登录
          },
          component: () => import(/* webpackChunkName: "ImportApply" */ '../views/result/importApplyScene.vue'),
        },
        {
          path: 'import',
          name: 'ImportData',
          meta:{
            label: 'ImportData',
            isIntercept:true,//是否路由拦截到登录
          },
          component: () => import(/* webpackChunkName: "ImportData" */ '../views/result/importData.vue'),
        },
        {
          path: 'report',
          name: 'Report',
          component: () => import(/* webpackChunkName: "Report" */ '../views/result/report.vue'),
          meta:{
            label:'Report',
            isIntercept:true,//是否路由拦截到登录
          },
        },
        {
          path: 'craft',
          name: 'Craft',
          meta:{
            isIntercept:true,//是否路由拦截到登录
          },
          component: () => import(/* webpackChunkName: "Craft" */ '../views/result/craft.vue'),
          meta:{
            label: 'Craft',
            isIntercept:true,//是否路由拦截到登录
            },
            children:[
              {
                path: ':path',
                name:'CraftFile',
                meta:{
                  label: 'Craft',
                  isIntercept:true,//是否路由拦截到登录
                },
              }
            ]
        },
        {
          path: 'winding',
          name: 'Winding',
          component: () => import(/* webpackChunkName: "Winding" */ '../views/result/winding.vue'),
          meta:{
            label: 'Winding',
            isIntercept:true,//是否路由拦截到登录
          },
          children:[
            {
              path: ':path',
              name:'WindingFile',
              meta:{
                label: 'Winding',
                isIntercept:true,//是否路由拦截到登录
              },
             
            }
          ]
        },
        {
          path: 'drawing',
          name: 'Drawing',
          component: () => import(/* webpackChunkName: "Drawing" */ '../views/result/drawing.vue'),
          meta:{
            label:'Drawing',
            isIntercept:true,//是否路由拦截到登录
          },
          children:[
            {
              path: ':path',
              name:'DrawingFile',
              meta:{
                label: 'Drawing',
                isIntercept:true,//是否路由拦截到登录
              },
            }
          ]
        },
        {
          path: 'bom',
          name: 'Bom',
          component: () => import(/* webpackChunkName: "Bom" */ '../views/result/bom.vue'),
          meta:{
            label:'Bom',
            isIntercept:true,//是否路由拦截到登录
          },
          children:[
            {
              path: ':path',
              name:'BomFile',
              meta:{
                label: 'Bom',
                isIntercept:true,//是否路由拦截到登录
              },
            }
          ]
        },
        //扫描图表配置页面
        {
          path: 'graph',
          name: 'DataGraph',
          component: () => import(/* webpackChunkName: "DataGraph" */ '../components/result/DataGraph.vue'),
          meta:{
            label:'Graph',
            isIntercept:true,//是否路由拦截到登录
          },
        },
      ]
    },

    {
      path: '/resultview/:productUUID',
      name: 'ResultviewIndex',
      meta:{
        isIntercept:true,//是否路由拦截到登录
      },
      redirect:'/result/:productUUID/import',
      component:() => import(/* webpackChunkName: "ResultIndex" */ '../views/ResultIndex.vue'),
      //路由守卫
      // beforeEnter:(to,from,next)=>{
      //   if(store.state.productUUID){//判断当前产品id
      //       next()//执行
      //   }else{
      //     messageOnce.error({
      //       message: getLangObj().routeMenu.messageProduct, //报错信息
      //       type: "error",
      //       showClose:true
      //     });
      //     next('/engineer')
      //   }
      // },
      children:[

        {
          path: 'housing',
          name: 'ResultviewHousing',
          meta:{
            label: 'Housing',
            isIntercept:true,//是否路由拦截到登录
          },
          component: () => import(/* webpackChunkName: "ImportHousing" */ '../views/result/importHousing.vue'),
        },
        {
          path: 'importapply',
          name: 'ResultviewImportApply',
          meta:{
            label: 'ImportApply',
            isIntercept:true,//是否路由拦截到登录
          },
          component: () => import(/* webpackChunkName: "ImportApply" */ '../views/result/importApplyScene.vue'),
        },
        {
          path: 'import',
          name: 'ResultviewImportData',
          meta:{
            label: 'ImportData',
            isIntercept:true,//是否路由拦截到登录
          },
          component: () => import(/* webpackChunkName: "ImportData" */ '../views/result/importData.vue'),
        },
        {
          path: 'report',
          name: 'ResultviewReport',
          component: () => import(/* webpackChunkName: "Report" */ '../views/result/report.vue'),
          meta:{
            label:'Report',
            isIntercept:true,//是否路由拦截到登录
          },
        },
        {
          path: 'craft',
          name: 'ResultviewCraft',
          meta:{
            isIntercept:true,//是否路由拦截到登录
          },
          component: () => import(/* webpackChunkName: "Craft" */ '../views/result/craft.vue'),
          meta:{
            label: 'Craft',
            isIntercept:true,//是否路由拦截到登录
            },
            children:[
              {
                path: ':path',
                name:'ResultviewCraftFile',
                meta:{
                  label: 'Craft',
                  isIntercept:true,//是否路由拦截到登录
                },
              }
            ]
        },
        {
          path: 'winding',
          name: 'ResultviewWinding',
          component: () => import(/* webpackChunkName: "Winding" */ '../views/result/winding.vue'),
          meta:{
            label: 'Winding',
            isIntercept:true,//是否路由拦截到登录
          },
          children:[
            {
              path: ':path',
              name:'ResultviewWindingFile',
              meta:{
                label: 'Winding',
                isIntercept:true,//是否路由拦截到登录
              },
             
            }
          ]
        },
        {
          path: 'drawing',
          name: 'ResultviewDrawing',
          component: () => import(/* webpackChunkName: "Drawing" */ '../views/result/drawing.vue'),
          meta:{
            label:'Drawing',
            isIntercept:true,//是否路由拦截到登录
          },
          children:[
            {
              path: ':path',
              name:'ResultviewDrawingFile',
              meta:{
                label: 'Drawing',
                isIntercept:true,//是否路由拦截到登录
              },
            }
          ]
        },
        {
          path: 'bom',
          name: 'ResultviewBom',
          component: () => import(/* webpackChunkName: "Bom" */ '../views/result/bom.vue'),
          meta:{
            label:'Bom',
            isIntercept:true,//是否路由拦截到登录
          },
          children:[
            {
              path: ':path',
              name:'ResultviewBomFile',
              meta:{
                label: 'Bom',
                isIntercept:true,//是否路由拦截到登录
              },
            }
          ]
        },
        //电流源扫描图表配置页面
        {
          path: 'graph',
          name: 'ResultviewDataGraph',
          component: () => import(/* webpackChunkName: "DataGraph" */ '../components/result/DataGraph.vue'),
          meta:{
            label:'Graph',
            isIntercept:true,//是否路由拦截到登录
          },
        },
      ]
    },
    //todo 企业人员管理
    {
      path: "/member",
      name: "BussinessMember",
      redirect: "/member/overview",
      meta: {
        label: 'Member'+'/'+'UserSettingOverview',
        isIntercept:true,//是否路由拦截到登录
      },
      component: () =>
      import(
        /* webpackChunkName: "BussinessMember" */ "../views/MemberIndex.vue"
      ),
      children: [
        {
          path: "/member/overview",
          name: "MemberOverview",
          meta: {
            label: 'Member'+'/'+'UserSettingOverview',
            isIntercept:true,//是否路由拦截到登录
          },
          component: () =>
            import(
              /* webpackChunkName: "MemberOverview" */ "../views/member/MemberOverview.vue"
            ),
        },
        {
          path: "/member/admin",
          name: "MemberAdmin",
          meta:{
            isIntercept:true,//是否路由拦截到登录
          },
          redirect: "/member/admin/person",
        },
        {
          path: "/member/admin/person",
          name: "AdminPerson",
          meta: {
            label: 'Member'+'/'+'memberControl'+'/'+'Person',
            isIntercept:true,//是否路由拦截到登录
          },
          component: () =>
            import(
              /* webpackChunkName: "AdminPerson" */ "../views/member/AdminPerson.vue"
            ),
        },
        {
          path: "/member/admin/addperson",
          name: "AdminAddPerson",
          meta: {
            label: 'Member'+'/'+'memberControl'+'/'+'Person',
            isIntercept:true,//是否路由拦截到登录
          },
          component: () =>
            import(
              /* webpackChunkName: "AdminAddPerson" */ "../views/member/AdminAddPerson.vue"
            ),
        },
        // {
        //   path: '/member/admin/addlimit',
        //   name: 'AdminAddLimit',
        //   meta:{
        //       label: 'Member'+'/'+'memberControl'+'/'+'Person',
        //       isIntercept:true,//是否路由拦截到登录
        //   },
        //   component: () => import(/* webpackChunkName: "AdminAddLimit" */ '../views/member/AdminAddLimit.vue'),
        // },
        // {
        // path: '/member/admin/addgroup',
        // name: 'AdminAddGroup',
        // meta:{
        //     label: 'Member'+'/'+'memberControl'+'/'+'Group',
        //     isIntercept:true,//是否路由拦截到登录
        // },
        // component: () => import(/* webpackChunkName: "AdminAddGroup" */ '../views/member/AdminAddGroup.vue'),
        // },
        // {
        //       path: '/member/admin/group',
        //       name: 'AdminGroup',
        //       meta:{
        //           label: 'Member'+'/'+'memberControl'+'/'+'Group',
        //           isIntercept:true,//是否路由拦截到登录
        //       },
        //       component: () => import(/* webpackChunkName: "AdminGroup" */ '../views/member/AdminGroup.vue'),
        // },
        // {
        //   path: '/member/resource',
        //   name: 'MemberResource',
        //   meta:{
        //     isIntercept:true,//是否路由拦截到登录
        //   },
        //   redirect:'/member/resource/limit',
        // },
        // {
        //     path: '/member/resource/limit',
        //     name: 'ResourceLimit',
        //     meta:{
        //         label: ''Member''+'/'+'ResourceControl'+'/'+'Resource',
        //         isIntercept:true,//是否路由拦截到登录
        //     },
        //     component: () => import(/* webpackChunkName: "MemberResource" */ '../views/member/ResourceLimit.vue'),
        // },
      ],
    },
    //todo 用户相关
    //登录
    {
      path: '/login',
      name: 'AdminLogin',
      meta:{
        isIntercept:false,//是否路由拦截到登录
      },
      component: () => import(/* webpackChunkName: "AdminLogin" */ '../views/user/UserLogin.vue'),
    },
    //!<<<屏蔽试用购买 试用申请/购买入口
    // {
    //   path: '/request',
    //   name: 'UserRequest',
    // meta:{
    //   isIntercept:true,//是否路由拦截到登录
    // },
    //   component: () => import(/* webpackChunkName: "UserRequest" */ '../views/user/UserRequest.vue'),
    // },
    {
      path: '/goods',
      name: 'UserGoods',
      component: () => import(/* webpackChunkName: "UserGoods" */ '../views/user/UserGoods.vue'),
      meta:{
        isIntercept:true,//是否路由拦截到登录
      },
    },
    //试用申请
    {
      path: '/usertrial',
      name: 'UserTrial',
      component: () => import(/* webpackChunkName: "UserTrial" */ '../views/user/UserTrial.vue'),
      meta:{
        isIntercept:true,//是否路由拦截到登录
      },
      beforeEnter:async(to,from,next)=>{
        if(localStorage.getItem('trialStage')!='NONE'
        &&localStorage.getItem('trialStage')!='FIRST_REVIEW'
        &&localStorage.getItem('trialStage')!='FIRST_REJECTED'
        &&localStorage.getItem('trialStage')!='FIRST_EXCEED'
        &&localStorage.getItem('trialStage')!='SECOND_REVIEW'
        &&localStorage.getItem('trialStage')!='SECOND_REJECTED'||
        (localStorage.getItem('accountType') == 'BUSINESS_SUB')
        ){//判断当前产品id和用户身份
          messageOnce.error({
            message: getLangObj().routeMenu.cannotTrial, //报错信息
            type: "error",
            showClose:true
          });
          next({ path: from.path || '/' })
          return
        }else{
          next()//执行
        }
      },
    },
    //!屏蔽试用购买 试用申请/购买入口>>>
    //注册
    {
      path: '/register',
      name: 'UserRegister',
      meta:{
        isIntercept:false,//是否路由拦截到登录
      },
      component: () => import(/* webpackChunkName: "UserRegister" */ '../views/user/UserRegister.vue'),
    },
    //市场试用申请表格
    {
      path: '/ontrial',
      name: 'UserOntrial',
      meta:{
        isIntercept:false,//是否路由拦截到登录
      },
      component: () => import(/* webpackChunkName: "UserOntrial" */ '../views/user/UserOntrial.vue'),
      //路由守卫
      beforeEnter:(to,from,next)=>{
        document.title='Virtual Engineer'
        window.location.href='http://p0hqph6cfgzkdiu5.mikecrm.com/NaOAUZX'
        window.document.body.style.display = "none";
        if(/mobile/i.test(navigator.userAgent)){
          window.document.body.style.minWidth = "0px";
        }
        next()
      },
      beforeDestroy:(to,from,next)=>{
        window.document.body.style.minWidth = "1080px";
        next();
      },
    },
    //认证
    {
      path: '/certificate',
      name: 'UserCertificate',
      meta:{
        isIntercept:false,//是否路由拦截到登录
      },
      component: () => import(/* webpackChunkName: "UserCertificate" */ '../views/user/UserCertificate.vue'),
    },
    //!<<<屏蔽试用购买
    {
      path: '/pay',
      name: 'UserPay',
      meta:{
        isIntercept:true,//是否路由拦截到登录
      },
      component: () => import(/* webpackChunkName: "UserPay" */ '../views/user/UserPay.vue'),
    },
    {
      path: '/paydone',
      name: 'UserPayDone',
      meta:{
        isIntercept:true,//是否路由拦截到登录
      },
      component: () => import(/* webpackChunkName: "UserPayDone" */ '../views/user/UserPayDone.vue'),
    },
    // !屏蔽试用购买>>>
    //用户信息设置
    {
      path: '/usersetting',
      name: 'UserSettingIndex',
      redirect: "/usersetting/overview",
      meta:{
        isIntercept:true,//是否路由拦截到登录
      },
      component: () => import(/* webpackChunkName: "UserSettingIndex" */ '../views/UserSettingIndex.vue'),
      children: [
        //!<<<屏蔽试用购买
        {
          path: "/usersetting/overview",
          name: "UserSettingOverview",
          meta: {
            label: 'UserSettingOverview',
            isIntercept:true,//是否路由拦截到登录
          },
          component: () =>
            import(
              /* webpackChunkName: "UserSettingOverview" */ "../views/userSetting/Overview.vue"
            ),
        },
        //!屏蔽试用购买>>>
        {
          name: "UserSettingAccount",
          path: "/usersetting/account",
          meta:{
            isIntercept:true,//是否路由拦截到登录
          },
          redirect: "/usersetting/account/info",
        },
        {
          path: "/usersetting/account/info",
          name: "AccountInfo",
          meta: {
            label: 'Account'+'/'+'AccountInfo',
            isIntercept:true,//是否路由拦截到登录
          },
          component: () =>
            import(
              /* webpackChunkName: "AccountInfo" */ "../views/userSetting/AccountInfo.vue"
            ),
        },
        {
          path: "/usersetting/account/security",
          name: "AccountSecurity",
          meta: {
            label: 'Account'+'/'+'AccountSecurity',
            isIntercept:true,//是否路由拦截到登录
          },
          component: () =>
            import(
              /* webpackChunkName: "AccountSecurity" */ "../views/userSetting/AccountSecurity.vue"
            ),
        },
        //!<<<屏蔽试用购买
        {
          path: "/usersetting/order",
          name: "UserSettingOrder",
          meta:{
            isIntercept:true,//是否路由拦截到登录
          },
          redirect: "/usersetting/order/virtualengineer",
        },
        {
          path: "/usersetting/order/virtualengineer",
          name: "OrderVirtualEngineer",
          meta: {
            label:'Order'+'/'+'engineer',
            isIntercept:true,//是否路由拦截到登录
          },
          component: () =>
            import(
              /* webpackChunkName: "OrderVirtualEngineer" */ "../views/userSetting/OrderIndex.vue"
            ),
        },
        //!屏蔽试用购买>>>
      ],
    },
    //todo 分享
    {
      path: '/share',
      name: 'Share',
      meta: {
        isIntercept:true,//是否路由拦截到登录
      },
      component: () => import(/* webpackChunkName: "Share" */ '../views/Share.vue'),
      beforeEnter:(to,from,next)=>{
        if(/mobile/i.test(navigator.userAgent)){
          window.document.body.style.minWidth = "0px";
        }
        next();
      },
      beforeDestroy:(to,from,next)=>{
        window.document.body.style.minWidth = "1080px";
        next();
      },
    },
    //todo 设计页面
    {
      path: '/design*',
      name: 'DesignIndex',
      meta: {
        isIntercept:true,//是否路由拦截到登录
      },
      component: () => import(/* webpackChunkName: "DesignIndex" */ '../views/DesignIndex.vue'),
      //路由守卫
      beforeEnter:(to,from,next)=>{
        if(to.path.includes('/designProId')){
          localStorage.setItem('/productUUID'+to.path.slice(7),to.path.slice(12))
        }
        let arr=Object.keys(localStorage)
        let flag=false
        arr.forEach(item=>{
          //清除unload的缓存
          if(item.includes('status')&&item!=='status'+to.path.slice(7)&&localStorage[item]=='unload'){
            localStorage.removeItem(item)
            if(localStorage.getItem('/productUUID'+item.slice(6))){
              localStorage.removeItem('/productUUID'+item.slice(6))
            }
            if(localStorage.getItem('/design'+item.slice(6))){
              localStorage.removeItem('/design'+item.slice(6))
            }
          }
          //判断有无缓存(新建设计+重新设计+online接口设计)
          if(item==to.path||(item.includes('/productUUID')&&(item.slice(12)==to.path.slice(7)))){
            flag=true
          }
        })
        if(flag){
          next()
        }else{
          messageOnce.error({
            message:getLangObj().routeMenu.messageDesign, //报错信息
            type: "error",
            showClose:true
          });
          next('/engineer/clacsuccess')
        }
      },
    },
    //导入冲片选择页面
    {
      path: '/lamselect',
      name: 'LamSelect',
      meta: {
        isIntercept:true,//是否路由拦截到登录
      },
      component: () => import(/* webpackChunkName: "LamSelect" */ '../views/lam/LamSelect.vue'),
    },
    //导入dxf冲片页面
    {
      path: '/dxflam*',
      name: 'DxfLam',
      meta: {
        isIntercept:true,//是否路由拦截到登录
      },
      component: () => import(/* webpackChunkName: "DxfLamIndex" */ '../views/lam/DxfLamIndex.vue'),
      beforeEnter:(to,from,next)=>{
        if(localStorage.getItem(to.path)){
            next()
        }else{
          next(from.path)
        }
      }
    },
    //新建冲片页面
    {
      path: '/createlam*',
      name: 'CreateLam',
      meta: {
        isIntercept:true,//是否路由拦截到登录
      },
      component: () => import(/* webpackChunkName: "CreateLam" */ '../views/lam/CreateLam.vue'),
    },
    //对比页面
    {
      path: '/contrast/:productUUID',
      name: 'Contrast',
      meta: {
        isIntercept:true,//是否路由拦截到登录
      },
      component: () => import(/* webpackChunkName: "Contrast" */ '../components/engineer/Contrast.vue'),
      //路由守卫
      beforeEnter:(to,from,next)=>{
        //删除其它contrastunload产品页面本地存储
        let arr=Object.keys(localStorage)
        let flag=false//判断有无本地存储
        arr.forEach((item)=>{
          if(item.includes('status/contrast/')){
            if(localStorage.getItem(item)=='unload'){
              localStorage.removeItem(item)
              if(localStorage.getItem(item.slice(6))){
                localStorage.removeItem(item.slice(6))
              }
            }
          }
          if(item==to.path){
            flag=true
          }
        })
        if(!flag){
          messageOnce.error({message:getLangObj().routeMenu.messageContrast,type:'error',showClose:true})
          next('/engineer/clacsuccess')
        }else{
          next()
        }
      }
    },
    //首页
    {
      path: '/home',
      name: 'Home',
      meta: {
        isIntercept:false,//是否路由拦截到登录
      },
      component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    },
    //用户协议和隐私政策
    {
      path: '/useragreement',
      name: 'UserAgreement',
      meta: {
        isIntercept:false,//是否路由拦截到登录
      },
      component: () => import(/* webpackChunkName: "UserAgreement" */ '../components/user/UserAgreement.vue'),
      beforeEnter:(to,from,next)=>{
        window.document.body.style.minWidth = "0px";
        next();
      },
      beforeDestroy:(to,from,next)=>{
        window.document.body.style.minWidth = "1080px";
        next();
      },
    },
    {
      path: '/userprivacypolicy',
      name: 'UserPrivacyPolicy',
      meta: {
        isIntercept:false,//是否路由拦截到登录
      },
      component: () => import(/* webpackChunkName: "UserPrivacyPolicy" */ '../components/user/UserPrivacyPolicy.vue'),
      beforeEnter:(to,from,next)=>{
        window.document.body.style.minWidth = "0px";
        next();
      },
      beforeDestroy:(to,from,next)=>{
        window.document.body.style.minWidth = "1080px";
        next();
      },
    },
    //帮助文档(网页端)
    {
      path: '/pchelp',
      name: 'PCHelp',
      meta: {
        isIntercept:false,//是否路由拦截到登录
      },
      component: () => import(/* webpackChunkName: "Help" */ '../views/user/PCHelp.vue'),
    },
    //后台配置页面
    {
      path: '/templatesetting',
      name: 'Backend',
      meta: {
        isIntercept:true,//是否路由拦截到登录
      },
      component: () => import(/* webpackChunkName: "Backend" */ '../views/Backend.vue'),
    },

    //!用户库
    {
      path: '/materiallib',
      name: 'MaterialLib',
      meta: {
        isIntercept:true,//是否路由拦截到登录
      },
      component: () => import(/* webpackChunkName: "MaterialLib" */ '../views/user/MaterialLib.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound', 
      meta: {
        isIntercept:false,//是否路由拦截到登录
      },
      component: () => import(/* webpackChunkName: "NotFound" */ '../views/user/NotFound.vue'),
    },


  ]
  export default menu;