function dispatchEventStroage() {
    //定义一个变量让setItem函数的值指向它
    let originalSetItem = localStorage.setItem;
    //重写setItem函数
    localStorage.setItem = function (key, newValue) {
        //创建setItemEvent事件
        let event = new Event("setItemEvent");
        event.key = key;
        event.newValue = newValue;
        //提交setItemEvent事件
        //执行原setItem函数
        originalSetItem.apply(this, arguments);
        window.dispatchEvent(event);
    }
}

export default dispatchEventStroage;