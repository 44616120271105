const isNewVersion =  (version,source) => {
  if (localStorage.getItem('version') && localStorage.getItem('version') == version&&sessionStorage.getItem('version') && sessionStorage.getItem('version') == version) {
  }else {
    window.location.reload(true);
    localStorage.setItem('version', version);
    sessionStorage.setItem('version', version);
    // 当需要刷新其他标签页时，设置localStorage
    // localStorage.setItem('refresh', new Date().toISOString());
    // localStorage.removeItem('refresh');
  }
};
export default {
isNewVersion
}