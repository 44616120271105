import Vue from 'vue'

import 'normalize.css'//格式化样式
import 'font-awesome/css/font-awesome.min.css'//fontasome图标
import '@/assets/font/font_3785824_tnwh9ma2of/iconfont.css'//iconfont
import '@/assets/plugin/element.js'//element

import dispatchEventStroage from '@/utils/dispatchEventStroage.js'//监听storage

import '@/assets/css/global.css'//封装的全局样式
// 引入插件
import VueLazyload from 'vue-lazyload'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入VueI18n
import VueI18n from 'vue-i18n'
//引入中英文js文件
import enLocale from '@/assets/lang/en.js'
import zhLocale from '@/assets/lang/zh.js'
//引入element 自带的语言包
import ElementLocale from 'element-ui/lib/locale'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN' // element-ui lang
// var print = console;
//     if (print && print.log) {
//       print.log(
//         'main'
//       );
//     }

//判断浏览器语言
var lang ='zh'
var type = navigator.appName;
if (type == "Netscape"){
    lang = navigator.language;//获取浏览器配置语言，支持非IE浏览器
}else{
    lang = navigator.userLanguage;//获取浏览器配置语言，支持IE5+ == navigator.systemLanguage
}
lang = lang.substr(0, 2);//获取浏览器配置语言前两位
if (lang == "zh"){
  console.log(lang);
}else if (lang == "en"){
  console.log(lang);
}else{//其他语言编码时
  lang == "en"
}
//注册 VueI18n
Vue.use(VueI18n);
Vue.use(dispatchEventStroage);

const i18n =new VueI18n({
  locale: localStorage.getItem('language') || lang, //默认浏览器语言语言
  messages:{
    en:{
      ...enLocale,
      ...elementEnLocale
    },
    zh:{
      ...zhLocale,
      ...elementZhLocale
    }
  }
})
localStorage.setItem('language',i18n.locale);
ElementLocale.i18n((key, value) => i18n.t(key, value))
//$message设为5s延迟
Vue.mixin({
  created() {
    // 覆盖Element UI Message的方法
    const originalMessage = this.$message;
    this.$message = function(options) {
      if (typeof options === 'string') {
        options = {
          message: options,
          duration: 5000 // 设置默认duration为5秒
        };
      } else if (options && typeof options === 'object') {
        options.duration = options.duration || 5000; // 设置默认duration为5秒
      }
      return originalMessage(options);
    };
  }
});
// 注册插件
Vue.use(VueLazyload,{
  loading:'' // 懒加载默认图片
})
Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
