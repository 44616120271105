import Vue from "vue";
import VueRouter from "vue-router";
import menu from "./menu";
import domMessage from "@/utils/messageOnce";
import Cookies from "js-cookie";


import { getImei,getMemberSettingInfo } from "@/api/user";
import enLocale from '@/assets/lang/en.js'
import zhLocale from '@/assets/lang/zh.js'
function getLangObj(){
  let langObj=null
  if(localStorage.getItem('language')&&localStorage.getItem('language')=='en'){
    langObj=enLocale
  }else{
    langObj=zhLocale
  }
  return langObj
}

const messageOnce = new domMessage();

//先保存一份VueRouter
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
//重写push|replace
//第一个参数：往哪里跳（传递哪些参数）
VueRouter.prototype.push = function(location,resolve,reject) {
    if(resolve && reject) {
        originPush.call(this,location,resolve,reject);
    } else {
        originPush.call(this,location,()=>{},()=>{});
    }
}
VueRouter.prototype.replace = function(location,resolve,reject) {
    if(resolve && reject) {
        originReplace.call(this,location,resolve,reject);
    } else {
        originReplace.call(this,location,()=>{},()=>{});
    }
}

Vue.use(VueRouter);

const routes = menu;

const router = new VueRouter({
  routes,
  mode: process.env.NODE_ENV == 'development'?"hash":"history", // todo 打包
  // 切换路由后滚动条置顶
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});
router.beforeEach(async(to, from, next) => {
  if (to.path === from.path) {
    // 当前路由相同，避免重复导航
    next(false);
    return
  }
  //  监听路由变化或刷新，重新请求数据和清空选项
  if(localStorage.getItem('language')&&localStorage.getItem('language')=='en'){
    document.title='Virtual Engineer'
  }else{
    document.title='数智工程师'
  }
  //todo 本地--start
  if (process.env.NODE_ENV == 'development'){
    if(!localStorage.getItem('imei')&&to.path !== "/login" ){
      getImei().then(
          res=>{localStorage.setItem('imei',res.entity);
          //没token需要登录验证
          if (to.meta && to.meta.isIntercept && !localStorage.getItem("token")) {
            console.log('isIntercept');
            localStorage.setItem("loginStatus", 'false');
            messageOnce.error({
              message: getLangObj().routeMenu.loginAgain, //报错信息
              type: "error",
              showClose:true,
            });
            //存储原链接地址
            if (!localStorage.getItem('fromSharePath')&&(to.fullPath.includes('share')||to.fullPath.includes('goods'))) {
              localStorage.setItem('fromSharePath',to.fullPath)
            }
            next('/login');
          }
          //没token不需要登录验证
          else if(!localStorage.getItem("token")){
            localStorage.setItem("loginStatus", 'false');
            //删除原有设计+结果参数+材料搜索记录+对比产品记录
            let arr=Object.keys(localStorage)
            arr.forEach((item)=>{
              if(item.includes('status')
              ||item.includes('/design')
              ||item.includes('productUUID')
              ||item.includes('queryList')
              ||item.includes('contrast')
              ||item.includes('/dxf')
              ||item.includes('queryList')
              ||item.includes('tempArr')
              ||item.includes('/create')){ 
                localStorage.removeItem(item)
              }
            })
            if(from.path.includes('/engineer')&&to.path.includes('/result')){
              to.meta.fromEngineerPath=from.path
            }
            next();
          }
          //有token
          else{
            //token错误(提前检查防止报错过多)
            if(from.path.includes('/engineer')&&to.path.includes('/result')){
              to.meta.fromEngineerPath=from.path
            }
            next();
          }
      });
    }
    //没token需要登录验证
    else if (to.meta && to.meta.isIntercept
      && !localStorage.getItem("token")) {
        localStorage.setItem("loginStatus", 'false');
        messageOnce.error({
          message: getLangObj().routeMenu.loginAgain, //报错信息
          type: "error",
          showClose:true,
        });
        //存储原链接地址
        if (!localStorage.getItem('fromSharePath')&&(to.fullPath.includes('share')||to.fullPath.includes('goods'))) {
          localStorage.setItem('fromSharePath',to.fullPath)
        }
        next('/login');
    }
    //没token不需要登录验证
    else if(!localStorage.getItem("token")){
      localStorage.setItem("loginStatus", 'false');
      //删除原有设计+结果参数+材料搜索记录+对比产品记录
      let arr=Object.keys(localStorage)
      arr.forEach((item)=>{
        if(item.includes('status')
        ||item.includes('/design')
        ||item.includes('productUUID')
        ||item.includes('queryList')
        ||item.includes('contrast')
        ||item.includes('/dxf')
        ||item.includes('queryList')
        ||item.includes('tempArr')
        ||item.includes('/create')){
          localStorage.removeItem(item)
        }
      })
      if(from.path.includes('/engineer')&&to.path.includes('/result')){
        to.meta.fromEngineerPath=from.path
      }
      next();
    }
    // 没认证需要登录验证
    else if(to.meta && to.meta.isIntercept
    && localStorage.getItem("userStatus")&& localStorage.getItem("userStatus")!=='passed'){
      messageOnce.error({
        message:getLangObj().routeMenu.toCertificate, //报错信息
        type: "error",
        showClose:true,
      });
      next('/certificate');
    }
    //登录已认证(可能有token错)
    else {
      if(from.path.includes('/engineer')&&to.path.includes('/result')){
        to.meta.fromEngineerPath=from.path
      }
      next();
    }
  }
  //todo 本地--end

  //todo 打包--start
  else{
    if (!Cookies.get("VM_APP_IMEI_4_PC")&&to.path !== "/login" ) {
      getImei().then(()=>{
        // 没token需要登录验证
        if (to.meta && to.meta.isIntercept 
        && !Cookies.get("VM_APP_COOKIE")) {
          localStorage.setItem("loginStatus", 'false');
          messageOnce.error({
            message: getLangObj().routeMenu.loginAgain, //报错信息
            type: "error",
            showClose:true,
          });
          if (!localStorage.getItem('fromSharePath')
          &&(to.fullPath.includes('share')||to.fullPath.includes('goods'))) {
            localStorage.setItem('fromSharePath',to.fullPath)
          }
          next('/login');
        }
        // 没token不需要登录验证
        else if(!Cookies.get("VM_APP_COOKIE")){
        localStorage.setItem("loginStatus", 'false');
          //删除原有设计+结果参数+材料搜索记录+对比产品记录
          let arr=Object.keys(localStorage)
          arr.forEach((item)=>{
            if(item.includes('status')
            ||item.includes('/design')
            ||item.includes('productUUID')
            ||item.includes('queryList')
            ||item.includes('contrast')
            ||item.includes('/dxf')
            ||item.includes('queryList')
            ||item.includes('tempArr')
            ||item.includes('/create')){
              localStorage.removeItem(item)
            }
          })
          if(from.path.includes('/engineer')&&to.path.includes('/result')){
            to.meta.fromEngineerPath=from.path
          }
          next();
        }
        //有token
        else{
          if(from.path.includes('/engineer')&&to.path.includes('/result')){
            to.meta.fromEngineerPath=from.path
          }
          next();
        }
      });
    }
    //没token需要登录验证
    else if (to.meta && to.meta.isIntercept
    && !Cookies.get("VM_APP_COOKIE")) {
      localStorage.setItem("loginStatus", 'false');
      messageOnce.error({
        message: getLangObj().routeMenu.loginAgain, //报错信息
        type: "error",
        showClose:true,
      });
      
      if (!localStorage.getItem('fromSharePath')&&(to.fullPath.includes('share')||to.fullPath.includes('goods'))) {
        localStorage.setItem('fromSharePath',to.fullPath)
      }
      next("/login");
    }
    //没token不需要登录验证
    else if(!Cookies.get("VM_APP_COOKIE")){
      localStorage.setItem("loginStatus", 'false');
      //删除原有设计+结果参数+材料搜索记录+对比产品记录
      let arr=Object.keys(localStorage)
      arr.forEach((item)=>{
        if(item.includes('status')
        ||item.includes('/design')
        ||item.includes('productUUID')
        ||item.includes('queryList')
        ||item.includes('contrast')
        ||item.includes('/dxf')
        ||item.includes('queryList')
        ||item.includes('tempArr')
        ||item.includes('/create')){
          localStorage.removeItem(item)
        }
      })
      if(from.path.includes('/engineer')&&to.path.includes('/result')){
        to.meta.fromEngineerPath=from.path
      }
      next();
    }
    // 登录没认证(屏蔽)
    else if(to.meta && to.meta.isIntercept
    && localStorage.getItem("userStatus")&& localStorage.getItem("userStatus")!=='passed'){
      messageOnce.error({
        message: getLangObj().routeMenu.toCertificate, //报错信息
        type: "error",
        showClose:true,
      });
      next('/certificate');
    }
    //登录已认证
    else {
      if(from.path.includes('/engineer')&&to.path.includes('/result')){
        to.meta.fromEngineerPath=from.path
      }
      next();
    }
  }

  //todo 打包--end
});
export default router;