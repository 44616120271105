import axios from "axios";
import router from '@/router';
import store from "@/store";
import domMessage from "./messageOnce";
import versionTood from '@/utils/versionUpdate';
import Cookies from "js-cookie";
import {addPending,removePending} from "./cancelToken.js"//引入cancelToken
const messageOnce = new domMessage();
// 定义axios配置 
//todo 本地运行环境---start
let baseURL = "";
if (process.env.NODE_ENV == "development") {
  baseURL = "/api";
}
//todo 本地运行环境---end
const request = axios.create({
  // baseURL: "", //todo 打包
  baseURL, //todo 本地
  timeout:30000, // 请求的超时时间
})
const request2 = axios.create({
  // baseURL: "", //todo 打包
  baseURL, //todo 本地
  timeout:30000, // 请求的超时时间
})

axios.defaults.headers["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
// 请求拦截器
request.interceptors.request.use(
  (config) => {
    config.headers["TerminalModel"] = navigator.userAgent;
    config.headers["TerminalSystem"] = "PC";
    if(localStorage.getItem('language')&&localStorage.getItem('language')=='en'){
      config.headers["LANGUAGE"] = "EN_US";//中英文ZH_CN、EN_US
    }else{
      config.headers["LANGUAGE"] = "ZH_CN";//中英文ZH_CN、EN_US
    }
    config.headers["AppVersion"] = "1.0";
    config.headers["Imei"] = "";
    config.headers["NetworkState"] = "OTHER";


    //todo 本地--start
    if (process.env.NODE_ENV == 'development'){
      if(localStorage.getItem('token')){
        config.headers["token"] = localStorage.getItem('token');
      }
      if(localStorage.getItem('imei')){
        config.headers["Imei"] = localStorage.getItem('imei');
      }
      if (Cookies.get("VM_APP_IMEI_4_PC")) {
        config.headers["Imei"] = Cookies.get("VM_APP_IMEI_4_PC");
      } //cookie||接口
      if (Cookies.get("VM_APP_COOKIE")) {
        config.headers["token"] = Cookies.get("VM_APP_COOKIE");
      }//登录之后有cookie
    }
    //todo 本地--end
    else{
      //todo 打包--start
      if (Cookies.get("VM_APP_IMEI_4_PC")) {
        config.headers["Imei"] = Cookies.get("VM_APP_IMEI_4_PC");
      } //cookie||接口
      if (Cookies.get("VM_APP_COOKIE")) {
        config.headers["token"] = Cookies.get("VM_APP_COOKIE");
      }//登录之后有cookie
      //todo 打包--end
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    if (!response.success && !response.data.success&&!response.config.url.includes('updateMemberMultiPhysicsOperationRecord')&&!response.config.url.includes('validateInputData')) {
        if (response.data.message || response.data.detail) {
          if(((response.code=='UNAUTHENTICATED_ERROR'||response.data.code=='UNAUTHENTICATED_ERROR')&&window.location.href.includes('home'))
          ||((response.code=='UNAUTHENTICATED_ERROR'||response.data.code=='UNAUTHENTICATED_ERROR')&&window.location.href.includes('ontrial'))
          ||((response.code=='UNAUTHENTICATED_ERROR'||response.data.code=='UNAUTHENTICATED_ERROR')&&window.location.href.includes('pchelp'))
          ||((response.code=='UNAUTHENTICATED_ERROR'||response.data.code=='UNAUTHENTICATED_ERROR')&&window.location.href.includes('login'))
          ||((response.code=='UNAUTHENTICATED_ERROR'||response.data.code=='UNAUTHENTICATED_ERROR')&&window.location.href.includes('register'))){
            //不报错
            //移除用户信息
            let arr=Object.keys(localStorage)
            arr.forEach((item)=>{
                if(item!=='language'&&item!=='version'){
                    localStorage.removeItem(item)
                }
            })
            localStorage.setItem('loginStatus','false')
            store.commit('changeUserInfo',null)
            store.commit('changeLoginStatus',false)
            if (Cookies.get("VM_APP_COOKIE")) {
              Cookies.remove("VM_APP_COOKIE");
            }
          }else{
            messageOnce.error({
              message: response.data.message || response.data.detail, //报错信息
              type: "error",
              showClose:true,
            });
          }
        }
        if((response.code=='UNAUTHENTICATED_ERROR'||response.data.code=='UNAUTHENTICATED_ERROR')){
          // console.log(window.location.href,window.location.hash.slice(1));
          if (!localStorage.getItem('fromSharePath')&&(window.location.hash.includes('share')||window.location.hash.includes('goods'))) {
            localStorage.setItem('fromSharePath',window.location.hash.slice(1))
          }
          if(localStorage.getItem('loginStatus')&&localStorage.getItem('loginStatus')=='true'){
              //移除用户信息
              let arr=Object.keys(localStorage)
              arr.forEach((item)=>{
                  if(item!=='language'&&item!=='version'){
                      localStorage.removeItem(item)
                  }
              })
              store.commit('changeUserInfo',null)
              store.commit('changeLoginStatus',false)
              localStorage.setItem('loginStatus','false')
              if (Cookies.get("VM_APP_COOKIE")) {
                Cookies.remove("VM_APP_COOKIE");
              }
          }
          if(!window.location.href.includes('home')&&!window.location.href.includes('/ontrial')&&!window.location.href.includes('pchelp')&&!window.location.href.includes('register')){
            router.push('/login')
          }
        }
    }
    if(response.headers&&response.headers['app-portal-version']){
      let version=response.headers['app-portal-version'];
      versionTood.isNewVersion(version,'axios')
    }
    return response.data;
  },
  (error) => {
      return Promise.reject(error);
  }
);


//加取消重复
// 请求拦截器
request2.interceptors.request.use(
  (config) => {
    config.headers["TerminalModel"] = navigator.userAgent;
    config.headers["TerminalSystem"] = "PC";
    config.headers["AppVersion"] = "1.0";
    if(localStorage.getItem('language')&&localStorage.getItem('language')=='en'){
      config.headers["LANGUAGE"] = "EN_US";//中英文ZH_CN、EN_US
    }else{
      config.headers["LANGUAGE"] = "ZH_CN";//中英文ZH_CN、EN_US
    }
    config.headers["Imei"] = "";
    config.headers["NetworkState"] = "OTHER";
    removePending(config) // 在请求开始前，对之前的请求做检查取消操作
    addPending(config) // 将当前请求添加到 pending 中
    
    //todo 本地--start
    if (process.env.NODE_ENV == 'development'){
      if(localStorage.getItem('token')){
        config.headers["token"] = localStorage.getItem('token');
      }
      if(localStorage.getItem('imei')){
        config.headers["Imei"] = localStorage.getItem('imei');
      } 
      if (Cookies.get("VM_APP_IMEI_4_PC")) {
        config.headers["Imei"] = Cookies.get("VM_APP_IMEI_4_PC");
      } //cookie||接口
      if (Cookies.get("VM_APP_COOKIE")) {
        config.headers["token"] = Cookies.get("VM_APP_COOKIE");
      }//登录之后有cookie
    }
    //todo 本地--end
    else{
      //todo 打包--start
      if (Cookies.get("VM_APP_IMEI_4_PC")) {
        config.headers["Imei"] = Cookies.get("VM_APP_IMEI_4_PC");
      } //cookie||接口
      if (Cookies.get("VM_APP_COOKIE")) {
        config.headers["token"] = Cookies.get("VM_APP_COOKIE");
      }//登录之后有cookie
      //todo 打包--end
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
request2.interceptors.response.use(
  (response) => {
    if (!response.success && !response.data.success) {
      // console.log(response);
      if (response.data.message || response.data.detail) {
        if(((response.code=='UNAUTHENTICATED_ERROR'||response.data.code=='UNAUTHENTICATED_ERROR')&&window.location.href.includes('home'))
        ||((response.code=='UNAUTHENTICATED_ERROR'||response.data.code=='UNAUTHENTICATED_ERROR')&&window.location.href.includes('ontrial'))
        ||((response.code=='UNAUTHENTICATED_ERROR'||response.data.code=='UNAUTHENTICATED_ERROR')&&window.location.href.includes('pchelp'))
        ||((response.code=='UNAUTHENTICATED_ERROR'||response.data.code=='UNAUTHENTICATED_ERROR')&&window.location.href.includes('login'))
        ||((response.code=='UNAUTHENTICATED_ERROR'||response.data.code=='UNAUTHENTICATED_ERROR')&&window.location.href.includes('register'))){
          //不报错
          //移除用户信息
          let arr=Object.keys(localStorage)
          arr.forEach((item)=>{
              if(item!=='language'&&item!=='version'){
                  localStorage.removeItem(item)
              }
          })
          store.commit('changeUserInfo',null)
          store.commit('changeLoginStatus',false)
          localStorage.setItem('loginStatus','false')
          if (Cookies.get("VM_APP_COOKIE")) {
            Cookies.remove("VM_APP_COOKIE");
          }
        }else{
          messageOnce.error({
            message: response.data.message || response.data.detail, //报错信息
            type: "error",
            showClose:true,
          });
        }
      }
      if(response.code=='UNAUTHENTICATED_ERROR'||response.data.code=='UNAUTHENTICATED_ERROR'){
        console.log(window.location.hash.slice(1));
        if (!localStorage.getItem('fromSharePath')&&(window.location.hash.includes('share')||window.location.hash.includes('goods'))) {
          localStorage.setItem('fromSharePath',window.location.hash.slice(1))
        }
        if(localStorage.getItem('loginStatus')&&localStorage.getItem('loginStatus')=='true'){
            //移除用户信息
            let arr=Object.keys(localStorage)
            arr.forEach((item)=>{
                if(item!=='language'&&item!=='version'){
                    localStorage.removeItem(item)
                }
            })
            store.commit('changeUserInfo',null)
            store.commit('changeLoginStatus',false)
            localStorage.setItem('loginStatus','false')
            if (Cookies.get("VM_APP_COOKIE")) {
              Cookies.remove("VM_APP_COOKIE");
            }
        }
        if(!window.location.href.includes('home')&&!window.location.href.includes('/ontrial')&&!window.location.href.includes('pchelp')&&!window.location.href.includes('register')){
          router.push('/login')
        }
      }
    }

    if(response.headers&&response.headers['app-portal-version']){
      let version=response.headers['app-portal-version'];
        versionTood.isNewVersion(version,'axios')
    }
    removePending(response) // 在请求结束后，移除本次请求
    return response.data;
  },
  (error) => {
    if (axios.isCancel(error)) {//处理手动cancel
      // console.log('这是手动cancel的')
    }else{
      return Promise.reject(error);
    }
    
  }
);


//暴露模块
export {request,request2} 